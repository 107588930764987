<template>
    <div class="user-message">
        <el-card class="box-card "   shadow="never">
            <div slot="header" class="clearfix">
                <span>我的需求</span>
                <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
            <el-card class="box-card list-card"  shadow="never">
                <div slot="header" class="clearfix">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="全部" name="all">

                        </el-tab-pane>
                        <el-tab-pane label="未处理" name="normal">

                        </el-tab-pane>
                        <el-tab-pane label="已处理" name="contacted">

                        </el-tab-pane>
                        <el-tab-pane label="不予处理" name="reject">

                        </el-tab-pane>

                    </el-tabs>
                    <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
                </div>
                <el-table
                        :data="listData.data"
                        border
                        style="width: 100%">
                    <el-table-column
                            v-for="item in tableColumn"
                            :prop="item.prop"
                            :label="item.label"
                            :width="item.width">
                    </el-table-column>
                    <el-table-column
                            align="center"
                            header-align="center"
                            prop=""
                            label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    style="margin-right: 5px"
                                    size="mini"
                                    @click="detail(scope.row)">查看</el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
            <el-pagination
                    style="text-align: center;margin-top: 10px"
                    background
                    layout="prev, pager, next"
                    :current-page="listData.current_page"
                    :page-size="Number(listData.per_page)"
                    :page-count="listData.last_page"
                    @current-change="currentChange"
                    :total="listData.total">
            </el-pagination>
        </el-card>


        <el-dialog
                title="需求详情"
                :visible.sync="dialogFormVisible"
                v-if="dialogFormVisible"
                width="650px"
                custom-class="msg-dialog"
                :modal="false"
        >
          <el-form ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <el-form-item label="需求标题："   prop="title">
<!--              <el-input v-model="ruleForm.title"></el-input>-->
              <span>{{ruleForm.title}}</span>
            </el-form-item>
<!--            <el-form-item label="需求类型："  prop="type">-->
<!--&lt;!&ndash;              <el-select v-model="ruleForm.type" placeholder="请选择需求类型">&ndash;&gt;-->
<!--&lt;!&ndash;                <el-option label="生物医药" value="生物医药"></el-option>&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash;                            <el-option label="区域二" value="beijing"></el-option>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              </el-select>&ndash;&gt;-->
<!--              <span>{{ruleForm.type}}</span>-->
<!--            </el-form-item>-->
            <el-form-item label="需求详情："  prop="dsc">
<!--              <el-input type="textarea" v-model="ruleForm.dsc"></el-input>-->
              <span>{{ruleForm.dsc}}</span>
            </el-form-item>
            <el-form-item label="需求所在地："  prop="areaCode">
              <span>{{ruleForm.area}}</span>
            </el-form-item>
<!--            <el-form-item label="需求图片/文件：" prop="file_url">-->

<!--            </el-form-item>-->
            <el-form-item label="需求预算："   prop="budget">
              <span>{{ruleForm.budget}}</span>
<!--              <el-input v-model="ruleForm.budget" style="width: 200px;margin-right: 5px"></el-input>-->
              <span>元</span>
            </el-form-item>
            <el-row>
              <el-col :span="10">
                <el-form-item label="手机号码："   prop="phone">
                  <span>{{ruleForm.phone}}</span>
<!--                  <el-input v-model="ruleForm.phone"  style="width: 200px;margin-right: 5px"></el-input>-->
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="截止时间："   prop="phone">
                  <span>{{ruleForm.endDate ? $formatDate(ruleForm.endDate) : ''}}</span>
                  <!--                  <el-input v-model="ruleForm.phone"  style="width: 200px;margin-right: 5px"></el-input>-->
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="10">
                <el-form-item label="所在单位："   prop="company">
                  <span>{{ruleForm.company}}</span>
<!--                  <el-input v-model="ruleForm.company" style="width: 200px;margin-right: 5px"></el-input>-->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="邮箱："  prop="email">
                  <span>{{ruleForm.email}}</span>
<!--                  <el-input v-model="ruleForm.email" style="width: 200px;margin-right: 5px"></el-input>-->
                </el-form-item>
              </el-col>

            </el-row>

            <el-form-item label="联系人："   prop="contact">
              <span>{{ruleForm.contact}}</span>
<!--              <el-input v-model="ruleForm.contact" style="width: 200px;margin-right: 5px"></el-input>-->
            </el-form-item>
              <el-form-item label="处理结果："   prop="result">
                  <span>{{ruleForm.result}}</span>
                  <!--              <el-input v-model="ruleForm.contact" style="width: 200px;margin-right: 5px"></el-input>-->
              </el-form-item>
          </el-form>




          <!--            <div slot="footer" class="dialog-footer" >-->
<!--                <el-button @click="dialogFormVisible = false">确定</el-button>-->
<!--            </div>-->
        </el-dialog>
    </div>
</template>

<script>
    import {UserService} from "../../../api/user";

    export default {
        name: "user-order-index",
        data() {
            return {
                activeName: 'all',
                activeType: 'system',
                page: 1,
                sum: 0,
                ruleForm: {},
                dialogFormVisible: false,
                isDetail: false,
                tableColumn: [
                  {
                    prop: 'title',
                    label: '需求标题',
                    width: '180'
                  },
                  {
                    prop: 'type',
                    label: '需求类型',
                    width: '180'
                  },
                  {
                    prop: 'area',
                    label: '需求地区',
                    width: '180'
                  },
                  {
                    prop: 'budget',
                    label: '需求预算',
                    width: '180'
                  },
                  {
                    prop: 'company',
                    label: '所在单位',
                    width: '120'
                  },
                  {
                    prop: 'status',
                    label: '状态',
                    width: '120'
                  }
                ],
                typeList: [
                    {
                        name: 'system',
                        label: '系统通知'
                    },
                    {
                        name: 'user',
                        label: '站内私信'
                    }
                ],
                formLabelWidth: '100px',
                listData: {
                    current_page: 1,
                    isRecommend: false,
                    last_page: 1,
                    data: [],
                    per_page: 1,
                    total: 0
                },
            }
        },
        methods: {
            detail(item) {
                // this.$dialog
              this.ruleForm = item
              this.dialogFormVisible = true
            },
            handleClick(tab, event) {
                this.listData.data = []
                this.page = 1
                this.getList()
            },
            currentChange(page) {
                this.page = page
                this.getList()
            },

            getList() {

                UserService.demand({ status:this.activeName, page: this.page }).then(res => {
                    const data = res.data
                    const statusName = {
                      normal: '未处理',
                      contacted: '已处理',
                      reject: '不予处理',
                    }
                    data.data = data.data.map(item => {
                        item.status = statusName[item.status]
                        return item
                    })
                    this.listData = data
                })
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>
<style lang="scss">
    .msg-dialog{

        .el-dialog__body{
            height: 600px;
            overflow: auto;
        }
    }
</style>
<style scoped lang="scss">
    .user-message{

        .content{
            /*height: 200px;*/
            height: 100%;
            .left{
                span{
                    color: $font_1;
                    font-size: 18px;
                }
            }
            .el-col:nth-child(1) {
                /*display: flex;*/
                /*justify-content: center;*/
                /*align-items: center;*/
                height: 100%;
                p:nth-child(1){
                    span{
                        font-size: 12px;
                        color: $font_2;
                    }
                }
                p:nth-child(2){
                    span{
                        font-size: 18px;
                        color: $danger;
                    }
                }
                .el-button{
                    /*margin-left: 20px;*/
                    /*float: right;*/
                }
            }
            .el-col:nth-child(2) {
                width: 1px;
                height: 100%;
                background-color: $border_1;
                margin: 0 20px;

            }
            .el-col:nth-child(3) {
                /*padding: 10px 0;*/
                margin-top: -20px;
                p{
                    font-size: 13px;
                    color: $font_2;
                    /*line-height: 1.6;*/
                    margin: 10px 0;
                }
            }
        }
        .el-tabs--border-card{
            box-shadow: none;
        }
        .list-card{
            height: 520px;
            margin-top: 13px;
        }
    }

</style>